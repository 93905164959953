const sectionsText = {
  title: {
    Ru: "Аудит-универсал | Москва | Аудит | Бухгалтерский учет",
    Eng: "Audit-Universal | Moscow | Audit | Accounting",
  },
  headerSection: {
    Ru: {
      AboutUs: "О нас",
      Services: "Услуги",
      Team: "Команда",
      Clients: "Клиенты",
      Feedback: "Обратная связь",
      Contacts: "Контакты",
      FAQ: "FAQ",
      Language: "Eng",
    },
    Eng: {
      AboutUs: "About Us",
      Services: "Services",
      Team: "Team",
      Clients: "Clients",
      Feedback: "Feedback",
      Contacts: "Contacts",
      FAQ: "FAQ",
      Language: "Ru",
    },
  },
  carouselSection: {
    Ru: {
      "First Slide": {
        Title: "ООО АУДИТ-УНИВЕРСАЛ",
        Description:
          "одна из немногих компаний на рынке аудиторских услуг, которая использует технологию «BIG DATA» в процессе проведения аудита",
        Button: "О компании",
      },
      "Second Slide": {
        Title: "Налогообложение и урегулирование споров",
        Description:
          "Мы помогаем бизнесу находить законные и безопасные налоговые решения",
        Button: "Об услугах",
      },
      "Third Slide": {
        Title: "Научно-исследовательская деятельность",
        Description:
          "В скором времени команда ООО АУДИТ-УНИВЕРСАЛ планирует публикацию результатов научно-исследовательской деятельности в новом разделе “Исследования”",
        Button: "О компании",
      },
      "Fourth Slide": {
        Title: "Калькулятор по расчету стоимости аудиторских услуг",
        Description:
          "Ведется разработка калькулятора по расчету стоимости аудиторских услуг на базе существующего механизма audit-it.ru",
        Button: "Об услугах",
      },
    },
    Eng: {
      "First Slide": {
        Title: "LLC AUDIT-UNIVERSAL",
        Description:
          "one of the few companies in the market of audit services, which uses Big Data Technologies in the process of auditing.",
        Button: "About the company",
      },
      "Second Slide": {
        Title: "Taxation and dispute resolution",
        Description: "We help businesses find legal and secure tax solutions",
        Button: "About services",
      },
      "Third Slide": {
        Title: "Research activities",
        Description:
          'LLC AUDIT-UNIVERSAL team is planning to publish the results of research activities in a new section "Research" in the near future',
        Button: "About the company",
      },
      "Fourth Slide": {
        Title: "Audit services cost calculator",
        Description:
          "We are developing an audit services cost calculator based on the existing audit-it.ru mechanism.",
        Button: "About services",
      },
    },
  },
  aboutUsSection: {
    Ru: {
      Title: "О нас",
      Description:
        "Отечественная аудиторская компания (ООО АУДИТ-УНИВЕРСАЛ) предоставляет услуги неизменно высокого качества компаниям всех отраслей. Мы обладаем уникальным опытом в сфере аудита. Мы отлично понимаем особенности рынка и потребности своих клиентов, поэтому многие нас рекомендуют как надёжную аудиторскую компанию.",
      FactsSubsection: {
        Name: "Факты",
        FactTitle_1: "С 2013 года",
        Fact_1: "занимаемся аудиторской деятельностью.",
        FactTitle_2: "Опыт проведения аудиторской проверки",
        Fact_2:
          "предприятий из более чем 50 различных сфер деятельности по ОКВЭД.",
        FactTitle_3:
          'ООО АУДИТ-УНИВЕРСАЛ является членом СРО ААС "Содружество"',
        Fact_3: "на основании решения от «02» декабря 2016 года №255.",
        "FactTitle_4-5": "Особые заслуги",
        Fact_4: "Более 40 благодарственных писем от наших клиентов;",
        Fact_5:
          "Грамота от Президента Федерации космонавтики России за активное участие в развитии космических программ.",
      },
      DocumentsSubsection: {
        Name: "Документы",
        DocumentTitle_1: "ОГРН",
        DocumentDescription_1: "1137746092692",
        DocumentTitle_2: "ИНН",
        DocumentDescription_2: "7706788690",
        DocumentTitle_3: "ОРНЗ",
        DocumentDescription_3: "11606065108",
        DocumentTitle_4: "Информация",
        DocumentDescription_4:
          "обязательная к раскрытию об аудиторской компании",
        AddressTitle: "Юридический адрес",
        AddressDescription:
          "115184, город Москва, внутригородская территория города федерального значения, муниципальный округ Замоскворечье, переулок Озерковский, дом 12",
      },
    },
    Eng: {
      Title: "About Us",
      Description:
        "Domestic audit company (LLC AUDIT-UNIVERSAL) provides consistently high quality services to companies in all industries. We have a unique experience in the field of auditing. We perfectly understand the peculiarities of the market and the needs of our clients, so many of them recommend us as a reliable auditing company.",
      FactsSubsection: {
        Name: "Facts",
        FactTitle_1: "Since 2013",
        Fact_1: "we have been engaged in auditing.",
        FactTitle_2: "Experience in Auditing",
        Fact_2:
          "companies from more than 50 different spheres of activity according to OKVED (all-Russian classifier of types of economic activity).",
        FactTitle_3: 'LLC AUDIT-UNIVERSAL is a member of SRO AAS "Sodrugestvo"',
        Fact_3: "on the basis of the decision No. 255 dated 02 December 2016.",
        "FactTitle_4-5": "Special Honours",
        Fact_4: "More than 40 letters of thanks from our clients;",
        Fact_5:
          "Diploma from the President of the Russian Cosmonautics Federation for active participation in the development of space programmes.",
      },
      DocumentsSubsection: {
        Name: "Documents",
        DocumentTitle_1: "OGRN (Primary State Registration Number)",
        DocumentDescription_1: "1137746092692",
        DocumentTitle_2: "INN (Taxpayer Identification Number)",
        DocumentDescription_2: "7706788690",
        DocumentTitle_3: "ORNZ (Principal Number of Registration Entry)",
        DocumentDescription_3: "11606065108",
        DocumentTitle_4: "Information",
        DocumentDescription_4:
          "required to be disclosed about the audit company",
        AddressTitle: "Legal address",
        AddressDescription:
          "Ozerkovsky lane, bldg. 12.  Zamoskvorechye municipal district, Intracity territory of a federal city, Moscow, 115184, Russia Federation",
      },
    },
  },
  pdfModal: {
    Ru: {
      buttons: {
        from: "Назад",
        to: "Вперед",
      },
      page: "страница",
      of: "из",
      download: "Скачать",
      choose: "Выберите документ за год:",
      error:
        "Не удалось загрузить PDF-документ, Вы можете попробовать еще раз, либо скачайте документ по ссылку внизу.",
    },
    Eng: {
      buttons: {
        from: "Prev",
        to: "Next",
      },
      page: "page",
      of: "of",
      download: "Download",
      choose: "Select the document for the year:",
      error:
        "Failed to download the PDF document, you can try again, or download the document from the link below.",
    },
  },
  servicesSection: {
    Ru: {
      Title: "Услуги",
      Description:
        "Наша компания предоставляет широкий спектр услуг как в области аудита, так и в области консалтинга. Наш главный принцип - «Качество — это точка отсчета, а не точка назначения».",
      AuditSubsection: {
        Name: "Аудит",
        AuditOptions: {
          FirstOption: {
            Title: "Обязательный аудит",
            Price: "290",
            Description: {
              Subject: "Для компаний:",
              FirstPoint: "с доходом свыше 800 тыс. руб.",
              SecondPoint: "с валютой баланса свыше 400 тыс. руб.",
            },
            Results: {
              Subject: "По окончании аудиторской проверки вы получите:",
              FirstPoint: "аудиторское заключение;",
              SecondPoint: "полный перечень выявленных аудитором замечаний.",
            },
          },
          SecondOption: {
            Title: "Инициативный аудит",
            Price: "210",
            Description:
              "Добровольная проверка, проводимая по желанию собственника или руководителя компании за любой период времени",
            Results: {
              Subject: "По окончании аудиторской проверки вы получите:",
              FirstPoint:
                "определение корректности ведения налогового и бухгалтерского учётов;",
              SecondPoint: "определение реального обстоятельства дел;",
              ThirdPoint: "оценка достоверности ведения бухучёта;",
              FourthPoint: "выявление и анализ налоговых рисков.",
            },
          },

          ThirdOption: {
            Title: "Аудит по специальному аудиторскому заданию",
            Price: "230",
            Description: {
              Subject: "Для компаний:",
              FirstPoint: "с доходом свыше 800 тыс. руб.",
              SecondPoint: "с валютой баланса свыше 400 тыс. руб.",
            },
            Results: {
              Subject: "По окончании аудиторской проверки вы получите:",
              FirstPoint:
                "проверка отдельных направлений деятельности и участков учёта;",
              SecondPoint: "определение сегментов по оптимизации расходов;",
              ThirdPoint:
                "анализ текущего финансово-хозяйственного состояния компании;",
              FourthPoint: "поддержка в снижении рисков и угроз.",
            },
          },
        },
        SimilarParts: {
          PrePricePart: "от",
          PastPricePart: "/ тыс руб.",
          CalculateButton: "Рассчитать",
        },
      },
      ConsultingSubsection: {
        Name: "Консалтинг",
        Services: {
          Subject:
            "Специалисты нашей компании оказывают следующий перечень услуг:",
          Service_1: "стратегическое развитие;",
          Service_2: "управление рисками;",
          Service_3: "управление проектной деятельностью;",
          Service_4: "аутсорсинг бизнес-процессов;",
          Service_5: "бухгалтерское консультирование;",
          Service_6: "налоговое консультирование;",
          Service_7: "разработка ПВК (правил внутреннего контроля);",
          Service_8: "pазработка внутренних стандартов;",
          Service_9: "формирование базы локальных нормативно-правовых актов;",
          Service_10:
            "подготовка и актуализация учетной политики для целей бухгалтерского и налогового учета;",
          Service_11: "кадровое консультирование;",
          Service_12: "обучение.",
        },
        Description:
          "При нестандартных сделках, постоянных изменениях в законодательстве очень часто даже самому квалифицированному сотруднику бухгалтерии или финансовой службы требуются разъяснения специалиста или стороннее мнение на сложившуюся ситуацию. Так же не малую важность играет срок получения необходимой информации.",
      },
    },
    Eng: {
      Title: "Services",
      Description:
        'Our company provides a wide range of services in both auditing and consulting. Our main principle is "Quality is a starting point, not an end point".',
      AuditSubsection: {
        Name: "Audit",
        AuditOptions: {
          FirstOption: {
            Title: "Statutory audit",
            Price: "290",
            Description: {
              Subject: "For companies:",
              FirstPoint: "with income over 800 thous. rub.",
              SecondPoint: "with total balance over 400 thous. rub.",
            },
            Results: {
              Subject: "Upon completion of the audit, you will receive:",
              FirstPoint: "an audit report;",
              SecondPoint:
                "a complete list of the observations identified by the auditor.",
            },
          },
          SecondOption: {
            Title: "Voluntary audit",
            Price: "210",
            Description:
              "Initiative audit, conducted at the request of the owner or manager of the company for any period of time.",
            Results: {
              Subject: "Upon completion of the audit, you will receive:",
              FirstPoint:
                "determination of the correctness of tax and accounting records;",
              SecondPoint: "determination of the true state of affairs;",
              ThirdPoint: "assessment of the reliability of accounting;",
              FourthPoint: "identification and analysis of tax risks.",
            },
          },
          ThirdOption: {
            Title: "Audit under a special audit assignment",
            Price: "230",
            Description: {
              Subject: "For companies:",
              FirstPoint: "with income over 800 thous. rub.",
              SecondPoint: "with total balance over 400 thous. rub.",
            },
            Results: {
              Subject: "Upon completion of the audit, you will receive:",
              FirstPoint:
                "verification of individual lines of business and accounting segments;",
              SecondPoint: "identification of segments for cost optimization;",
              ThirdPoint:
                "analysis of the current financial and economic state of the company;",
              FourthPoint: "support in mitigating risks and threats.",
            },
          },
        },
        SimilarParts: {
          PrePricePart: "from",
          PastPricePart: "thous. rub.",
          CalculateButton: "Calculate",
        },
      },
      ConsultingSubsection: {
        Name: "Consulting",
        Services: {
          Subject: "Our specialists provide the following range of services:",
          Service_1: "strategic development;",
          Service_2: "risk management;",
          Service_3: "project management;",
          Service_4: "business process outsourcing;",
          Service_5: "accounting consulting;",
          Service_6: "tax consulting;",
          Service_7: "development of internal control rules;",
          Service_8: "development of internal standards;",
          Service_9: "formation of the base of local normative legal acts;",
          Service_10:
            "preparation and updating of accounting policies for accounting and tax purposes;",
          Service_11: "HR consulting;",
          Service_12: "training.",
        },
        Description:
          "In cases of non-standard transactions and frequent changes in legislation even the most qualified employees in accounting or financial departments often require clarification from specialists or an external opinion on the situation at hand. The timely availability of necessary information is also of great importance.",
      },
    },
  },
  teamSection: {
    Ru: {
      Title: "Команда",
      Description:
        "В нашей компании работает 12 высококвалифицированных специалистов в области аудита и бухгалтерского (финансового) учета.",
      ManagementSubsection: {
        Name: "Руководство",
        ManagementTeam: {
          Manager_1: {
            Name: "Леонов Павел Юрьевич (51%)",
            Rank: "Генеральный директор",
            Achievements: {
              Achievement_1:
                "Окончил в 2009 году НИЯУ МИФИ, экономист по специальности «Бухгалтерский учет, анализ и аудит».",
              Achievement_2: "Окончил аспирантуру НИЯУ МИФИ в 2012 году.",
              Achievement_3:
                "В 2013 году защитил кандидатскую диссертацию по специальности 08.00.12 – Бухгалтерский учет, статистика.",
              Achievement_4:
                "В 2016 году сдал квалификационный экзамен и получил аттестат аудитора.",
              Achievement_5:
                "Имеет свыше 70 публикаций, из них три учебных пособия НИЯУ МИФИ (в соавторстве).",
            },
          },
          Manager_2: {
            Name: "Лейферов Борис Михайлович (49%)",
            Rank: "Исполнительный директор",
            Achievements: {
              Achievement_1:
                "В 1977 году окончил МИСИС по специальности инженер электронной техники (физика полупроводников и полупроводниковых приборов).",
              Achievement_2:
                "С 1977 г. по 2013 г. работал в отделе физических методов исследования НИИ ГИРЕДМЕТ.",
              Achievement_3:
                "В 1988 защитил кандидатскую диссертацию (кандидат физико-математических наук).",
              Achievement_4:
                "В 2013 году вошел в состав учредителей ООО АУДИТ-УНИВЕРСАЛ.",
            },
          },
        },
      },
      AuditorsSubsection: {
        Name: "Аудиторы",
        Auditors: {
          Auditor_1: {
            Name: "Дубинский Андрей Юрьевич",
            Rank: "Аудитор",
          },
          Auditor_2: {
            Name: "Ковалевская Ирина Николаевна",
            Rank: "Аудитор",
          },
          Auditor_3: {
            Name: "Леонов Павел Юрьевич",
            Rank: "Аудитор",
          },
          Auditor_4: {
            Name: "Тунева Ирина Сергеевна",
            Rank: "Аудитор, контролер качества",
          },
        },
      },
    },
    Eng: {
      Title: "Team",
      Description:
        "Our company employs 12 highly qualified specialists in the field of auditing and accounting.",
      ManagementSubsection: {
        Name: "Management",
        ManagementTeam: {
          Manager_1: {
            Name: "Leonov Pavel Yurievich (51%)",
            Rank: "Chief Executive Officer",
            Achievements: {
              Achievement_1:
                "Graduated in 2009 from the National Research Nuclear University MEPhI (Moscow Engineering Physics Institute), specializing in Accounting, Analysis, and Audit.",
              Achievement_2: "Completed postgraduate studies at MEPhI in 2012.",
              Achievement_3:
                "In 2013, defended a PhD dissertation in the specialty 08.00.12 - Accounting, Statistics.",
              Achievement_4:
                "In 2016, passed the qualification exam and obtained an auditor's certificate.",
              Achievement_5:
                "Pavel has over 70 publications, including three textbooks published at MEPhI (as a co-autor).",
            },
          },
          Manager_2: {
            Name: "Leiferov Boris Mikhailovich (49%)",
            Rank: "Executive Director",
            Achievements: {
              Achievement_1:
                "In 1977, graduated from MISiS (National University of Science and Technology) with a degree in Electronic Engineering (Semiconductor Physics and Semiconductor Devices).",
              Achievement_2:
                "From 1977 to 2013, worked in the Department of Physical Research Methods at the Research Institute of GIREDMET.",
              Achievement_3:
                "In 1988, defended a PhD dissertation in Physical and Mathematical Sciences.",
              Achievement_4:
                "In 2013, became one of the founders of LLC AUDIT-UNIVERSAL.",
            },
          },
        },
      },
      AuditorsSubsection: {
        Name: "Auditors",
        Auditors: {
          Auditor_1: {
            Name: "Dubinsky Andrey Yurievich",
            Rank: "Auditor",
          },
          Auditor_2: {
            Name: "Kovalevskaya Irina Nikolaevna",
            Rank: "Auditor",
          },
          Auditor_3: {
            Name: "Leonov Pavel Yurievich",
            Rank: "Auditor",
          },
          Auditor_4: {
            Name: "Tuneva Irina Sergeevna",
            Rank: "Auditor, Quality Controller",
          },
        },
      },
    },
  },
  clientsSection: {
    Ru: {
      Title: "Клиенты",
      Description: "Нам доверяют более 50 экономических субъектов",
    },
    Eng: {
      Title: "Clients",
      Description: "We are trusted by more than 50 economic entities",
    },
  },
  feedbackSection: {
    Ru: {
      Title: "Обратная связь",
      Description: {
        Treatment: "Уважаемые клиенты!",
        DescriptionPoint_1: {
          Text: "Компания ООО АУДИТ-УНИВЕРСАЛ стремится к постоянному совершенствованию качества предоставляемых услуг.",
        },
        DescriptionPoint_2: {
          Title: "Написать нам",
          Text: "Заполнив форму, вы сможете запросить коммерческое предложение, получить консультацию или заказать обратный звонок.",
        },
        DescriptionPoint_3: {
          Title: "Оценить качество услуг",
          Text: "Обеспечивая обратную связь, вы помогаете нам объективно оценить качество услуг, предоставляемых ООО АУДИТ-УНИВЕРСАЛ. Опираясь на ваши оценки и комментарии, мы вносим корректировки в работу, чтобы максимально соответствовать вашим ожиданиям. Если вы хотите оценить качество услуг, пожалуйста, ответьте на вопросы, опрос занимает не более 5 минут. Предоставляемые данные рассматриваются как строго конфиденциальные.",
        },
        DescriptionPoint_4: {
          Title: "Оставить отзыв",
          Text: "Рекомендации клиентов — это независимое подтверждение качества обслуживания и надежности бизнеса. Мы будем признательны вам за публичный отзыв об опыте работы с нашей компанией. Если вы готовы оставить отзыв, направьте, пожалуйста, письмо в свободной форме по адресу: ",
          MailLink: "a-universal@mail.ru",
        },
      },
      FeedbackBlock: {
        FeedbackRequestTitle: "Запрос обратной связи",
        Point_1: "Чем мы можем вам помочь?*",
        Point_2: "В какой услуге вы заинтересованы?",
        Point_3: "Представьтесь, пожалуйста*",
        Point_4: "Адрес электронной почты*",
        Point_5: "Номер телефона*",
        Point_6: "Ваша должность*",
        Point_7: "Название организации, в которой вы работаете*",
        Point_8: "Напишите сообщение",
        FeedbackOptions: {
          type: [
            "Выберите тему обращения",
            "Запросить коммерческое предложение",
            "Нужна консультация",
            "Прошу перезвонить",
            "Хочу оставить отзыв о вашей компании",
            "Другое",
          ],
          service: [
            "Выберите услугу",
            "Аудит РСБУ",
            "Аудит МСФО",
            "Бухгалтерское консультирование",
            "Налоговое консультирование",
            "Аутсорсинг",
            "Обучение",
          ],
          placeholders: {
            FullName: "Укажите ваше ФИО",
            Position: "Укажите вашу должность",
            Organization: "Название организации",
            Message: "Ваше сообщение",
          },
        },
        FeedbackErrorMessages: {
          type: "Выберите пожалуйста значение из списка",
          fullname: "Введите пожалуйста как к Вам обращаться",
          email: "Введите Ваш email",
          phone: "Введите номер телефона для связи",
          position: "Укажите пожалуйста вашу должность",
          organization: "Укажите пожалуйста вашу организацию",
          attachment: "Размер файла не может превышать 5Мб",
        },
        FeedbackSuccessMessages: {
          success: "Мы уже получили Ваше сообщение, ответим в ближайшее время",
          error:
            "Произошла ошибка при отправке, повторите пожалуйста позже или напишите нам на почту a-universal@mail.ru",
        },
        FeedbackUpdatedErrorMessages: {
          email: "Проверьте пожалуйста правильность ввода email",
          phone: "Проверьте пожалуйста правильность ввода телефона",
        },
        FileLoadBlock: {
          Title: "Добавить файл",
          AddButton: "Добавить",
          FileStatus: "Файл не добавлен",
          ResetButton: "Сбросить данные",
          SendButton: "Отправить",
        },
      },
    },
    Eng: {
      Title: "Feedback",
      Description: {
        Treatment: "Dear clients!",
        DescriptionPoint_1: {
          Text: "LLC AUDIT-UNIVERSAL strives for constant improvement in the quality of services provided.",
        },
        DescriptionPoint_2: {
          Title: "Contact Us",
          Text: "By filling out the form, you can request a commercial offer, get a consultation, or request a callback.",
        },
        DescriptionPoint_3: {
          Title: "Assess the quality of services",
          Text: "By providing feedback, you help us objectively assess the quality of services provided by LLC AUDIT-UNIVERSAL. Based on your ratings and comments, we make adjustments to our work to better meet your expectations. If you would like to evaluate the quality of our services, please answer the questions. The survey will take no more than 5 minutes. The provided data is considered strictly confidential.",
        },
        DescriptionPoint_4: {
          Title: "Leave a review",
          Text: "Client recommendations are an independent confirmation of the quality of service and reliability of our business. We would be grateful to you for a public review of your experience working with our company. If you are ready to leave a review, please send an email in any format to: ",
          MailLink: "a-universal@mail.ru",
        },
      },
      FeedbackBlock: {
        FeedbackRequestTitle: "Feedback request",
        Point_1: "How can we help you?",
        Point_2: "What service are you interested in?",
        Point_3: "Introduce yourself, please*",
        Point_4: "E-mail address*",
        Point_5: "Phone number*",
        Point_6: "Your position*",
        Point_7: "Name of the organization you work for*",
        Point_8: "Write a message",
        FeedbackOptions: {
          type: [
            "Choose a topic",
            "Request a commercial offer",
            "Need a consultation",
            "Please call back",
            "I want to leave feedback about your company ",
            "Other",
          ],
          service: [
            "Select a service",
            "RAS audit",
            "IFRS audit",
            "Accounting consulting",
            "Tax consulting",
            "Outsourcing",
            "Training",
          ],
          placeholders: {
            FullName: "Enter your full name",
            Position: "Indicate your position",
            Organization: "Name of organization",
            Message: "Your message",
          },
        },
        FeedbackErrorMessages: {
          type: "Please select a option from the list",
          fullname: "Please enter how to refer to you",
          email: "Enter your email",
          phone: "Enter the phone number to contact you",
          position: "Please state your job title",
          organization: "Please specify your organization",
          attachment: "The file size cannot exceed 5MB",
        },
        FeedbackSuccessMessages: {
          success: "We have already received your message, we will reply soon",
          error:
            "There was an error while sending, please try again later or email us at a-universal@mail.ru.",
        },
        FeedbackUpdatedErrorMessages: {
          email: "Please check if your email is correct",
          phone: "Please check if the phone number is correct",
        },
        FileLoadBlock: {
          Title: "Add file",
          AddButton: "Add",
          FileStatus: "File not added",
          ResetButton: "Reset data",
          SendButton: "Send",
        },
      },
    },
  },
  serviceEvaluationSection: {
    Ru: {
      EvaluationFormButton: "Форма для оценки качества услуг",
      Title: "Независимая оценка качества услуг компании ООО АУДИТ-УНИВЕРСАЛ",
      Description:
        "Здравствуйте! Благодарим Вас за готовность предоставить обратную связь! Просим Вас дать честную и развернутую оценку, особенно если ситуация требует нашего внимания. Все предоставляемые Вами данные будут рассматриваться как строго конфиденциальные.",
      FillRequest:
        "Чтобы мы могли связаться с Вами, заполните, пожалуйста, поля ниже:",
      PersonalDataInput: [
        "Представьтесь, пожалуйста",
        "Укажите название компании",
        "Укажите Вашу должность",
      ],
      InputPlaceholders: [
        "Укажете ваше ФИО",
        "Название организации",
        "Укажите Вашу должность",
        "Укажете ФИО сотрудника",
      ],
      ServicesEvaluationFillRequest: "Какие услуги Вы хотели бы оценить?",
      ServicesForEvaluationChoose: [
        "Аудит",
        "Форензик",
        "Налоговое консультирование",
        "Бизнес-консультирование",
        "Правовое консультирование",
        "Финансовое моделирование и бизнес-планирование",
        "Сопровождение сделок с капиталом",
        "Другое",
        "Укажите услугу, которую хотели бы оценить",
      ],
      PreviousEvaluationQuestion: {
        Question: "Вы уже оценивали качество наших услуг?",
        Yes: "Да, я отвечала/отвечал на вопросы анкеты в прошлом/позапрошлом году",
        No: "Нет, я отвечаю на вопросы анкеты впервые",
      },
      EvaluationRequest:
        "Пожалуйста, оцените по шкале от 1 до 10 следующие утверждения:",
      EvaluationTopics: [
        "Компания выполняет данные договоренности",
        "Услуги оказаны точно в срок",
        "Возникшие в ходе проекта проблемные и важные вопросы были решены своевременно",
        "Сотрудники ООО АУДИТ-УНИВЕРСАЛ понимают наши потребности и предлагают адекватные решения",
        "В ходе проекта сотрудники продемонстрировали высокий уровень профессионализма и компетенций",
        "Полнота проверки и отчета / консультации соответствует нашим ожиданиям",
        "Коммуникации во время проекта были выстроены четко и адекватно",
        "Как Вы оцениваете качество наших услуг?",
      ],
      EmployeeSelection:
        "Если Вы хотите выделить кого-то из наших сотрудников напишите его ФИО",
      RemarksRequest:
        "Пожалуйста, напишите свои отзывы, претензии и предложения — все, что поможет нам понять, в каком направлении компания должна совершенствовать предоставляемое Вам обслуживание. Будем рады, если Вы прокомментируете еще какие-то аспекты нашей работы, не упомянутые в этой анкете",
      Gratitude:
        "Благодарим Вас за то, что помогаете нам совершенствоваться! Нажимая на кнопку «Отправить», я даю согласие на обработку персональных данных",
      ReviewSuggestion: "Напишите отзыв",
      Buttons: ["Сбросить", "Отправить", "Далее", "Назад"],
      SuccessMessage: "Спасибо! Мы получили Ваш отзыв.",
      ErrorMessage:
        "К сожалению, не удалось отправить отзыв. Попробуйте еще раз или напишите нам на почту a-universal@mail.ru",
    },
    Eng: {
      EvaluationFormButton: "Service quality evaluation form",
      Title:
        "Independent assessment of the quality of services provided by LLC AUDIT-UNIVERSAL",
      Description:
        "Welcome! Thank you for your willingness to provide feedback! Please give us your honest and detailed feedback, especially if the situation requires our attention. All data you provide will be treated as strictly confidential.",
      FillRequest: "Please fill out the fields below so we can contact you:",
      PersonalDataInput: [
        "Introduce yourself, please",
        "Specify the company name",
        "Indicate your position",
      ],
      InputPlaceholders: [
        "Your full name",
        "Name of organization",
        "Indicate your position",
        "Enter the employee's full name",
      ],
      ServicesEvaluationFillRequest:
        "What services would you like to evaluate?",
      ServicesForEvaluationChoose: [
        "Audit",
        "Forensic",
        "Tax Consulting",
        "Business Consulting",
        "Legal Consulting",
        "Financial Modeling and Business Planning",
        "Capital transaction support",
        "Other",
        "Specify the service you would like to evaluate",
      ],
      PreviousEvaluationQuestion: {
        Question: "Have you evaluated the quality of our services yet?",
        Yes: "Yes, I answered/responded to the questionnaire last year/the year before that",
        No: "No, I'm answering the questionnaire for the first time.",
      },
      EvaluationRequest:
        "Please rate the following statements on a scale of 1 to 10:",
      EvaluationTopics: [
        "The company fulfills the given arrangements",
        "The services were delivered on time",
        "Problematic and important issues that arose during the project were resolved in a timely manner",
        "The staff of LLC AUDIT-UNIVERSAL understands our needs and offers adequate solutions",
        "During the project the staff demonstrated a high level of professionalism and competence",
        "Completeness of the audit and report/consultation meets our expectations",
        "Communication during the project was clear and adequate",
        "How would you rate the quality of our services?",
      ],
      EmployeeSelection:
        "If you would like to single out any of our employees, please write their full name",
      RemarksRequest:
        "Please write your feedback, complaints and suggestions - anything that will help us understand where we need to improve our service to you. We would be pleased if you could comment on any other aspects of our work not mentioned in this questionnaire",
      Gratitude:
        'Thank you for helping us to improve! By clicking on the "Send" button, I consent to the processing of personal data',
      ReviewSuggestion: "Write a review",
      Buttons: ["Reset", "Send", "Further", "Back"],
      SuccessMessage: "Thank you! We have received your feedback.",
      ErrorMessage:
        "Unfortunately, we were unable to submit a review. Try again or send us an e-mail a-universal@mail.ru",
    },
  },
  contactsSection: {
    Ru: {
      Title: "Контакты",
      Contacts: {
        AddressBlock: {
          Title: "Почтовый адрес",
          Address:
            "119017, г. Москва, Внутригородская территория города федерального значения МУНИЦИПАЛЬНЫЙ ОКРУГ ЯКИМАНКА, пер. Пыжёвский, д.5, стр.1",
        },
        OfficeBlock: {
          Title: "Офис",
          Office:
            "115184, город Москва, внутригородская территория города федерального значения, муниципальный округ Замоскворечье, переулок Озерковский, дом 12",
        },
        EmailBlock: {
          Title: "E-mail",
          Email: "a-universal@mail.ru",
        },
        PhoneBlock: {
          Title: "Телефон",
          Phone: "+7 (499) 130-79-96",
        },
      },
    },
    Eng: {
      Title: "Contacts",
      Contacts: {
        AddressBlock: {
          Title: "Postal address",
          Address:
            "119017, Moscow, Intracity territory of a federal city Yakimanka District, Pyzhyovsky Lane, 5, bldg. 1",
        },
        OfficeBlock: {
          Title: "Office",
          Office:
            "Ozerkovsky lane, bldg. 12. Zamoskvorechye municipal district, Intracity territory of a federal city, Moscow, 115184, Russia Federation",
        },
        EmailBlock: {
          Title: "E-mail",
          Email: "a-universal@mail.ru",
        },
        PhoneBlock: {
          Title: "Phone",
          Phone: "+7 (499) 130-79-96",
        },
      },
    },
  },
  faqSection: {
    Ru: {
      Title: "Остались вопросы?",
      MobileTitle: "Часто задаваемые вопросы",
      Description:
        "Если вы не нашли ответ на свой вопрос, вы можете связаться с нами через почту или форму обратной связи.",
      Email: "a-universal@mail.ru",
      WriteButton: "Написать",
      FAQBlock: {
        Point_1: {
          Question: "Как с вами связаться?",
          Answer:
            "Можете написать по почте, либо оставить заявку на сайте. В обоих случаях мы с вами быстро свяжемся.",
        },
        Point_2: {
          Question: "Могу ли я с вами связаться не в рабочее время?",
          Answer:
            "Вы можете оставить заявку в любое время, а в рабочее время вам сразу же ответят.",
        },
        Point_3: {
          Question: "Вы предоставляете онлайн или офлайн услуги?",
          Answer: "Наши услуги есть в обоих форматах.",
        },
        Point_4: {
          Question: "Предоставляете ли вы подписанные документы?",
          Answer:
            "Да, даже если ваша компания находится не в Москве все необходимые документы будут у вас в подписанном виде.",
        },
        Point_5: {
          Question: "Сколько времени занимает аудиторская проверка?",
          Answer:
            "Срок проведения аудиторской проверки зависит от масштабов и специфики деятельности Вашей компании. В среднем аудиторская проверка занимает 1 – 3 месяцев. Также важно учитывать этапы проведения, например по квартально или один этап проверки за год.",
        },
        Point_6: {
          Question: "Каковы основные этапы проведения аудита?",
          Answer:
            "Предварительный этап, Проверка по существу, Заключительный этап проверки (предоставляется отчет с замечаниями), Этап выдачи аудиторского заключения.",
        },
        Point_7: {
          Question: "Какие документы предоставляются по итогам аудита?",
          Answer:
            "Отчет о выявленных замечаниях, aудиторское заключение. Аудиторское заключение может быть модифицированным при наличии существенных искажений бухгалтерской отчетности и не модифицированным при их отсутствии. Аудиторское заключение сшивается с бухгалтерской отчетностью, аудит которой проводился.",
        },
        Point_8: {
          Question: "Какие документы нужно предоставлять аудитору?",
          Answer:
            "Это бухгалтерская и налоговая отчетность за проверяемый и предыдущий период, первичные документы (договора, акты, накладные, счета-фактуры, документы по начислению заработной платы, документы по учету основных средств и т.п.). Так же по договоренности Вы можете предоставить доступ, а лучше электронную копию бухгалтерской базы.",
        },
      },
    },
    Eng: {
      Title: "Any questions?",
      MobileTitle: "Frequently Asked Questions",
      Description:
        "If you have not found the answer to your question, you can contact us via mail or feedback form.",
      Email: "a-universal@mail.ru",
      WriteButton: "Write",
      FAQBlock: {
        Point_1: {
          Question: "How to contact us?",
          Answer:
            "You can reach us by email or submit a request on our website. In both cases, we will contact you promptly.",
        },
        Point_2: {
          Question: "Can I contact you outside of business hours?",
          Answer:
            "You can leave a request at any time, and you will be answered immediately during business hours.",
        },
        Point_3: {
          Question: "Do you provide online or offline services?",
          Answer: "We provide services in both formats.",
        },
        Point_4: {
          Question: "Do you provide signed documents?",
          Answer:
            "Yes, even if your company is located outside of Moscow, all necessary documents will be provided to you in signed form.",
        },
        Point_5: {
          Question: "How long does an audit typically take?",
          Answer:
            "The duration of an audit depends on the size and nature of your company's activities. On average, an audit can take from 1 to 3 months. It's also important to consider the stages of the audit, for example, quarterly audits or completing the audit in one stage for the entire year.",
        },
        Point_6: {
          Question: "What are the main stages of an audit?",
          Answer:
            "Preliminary stage, Substantive testing, Final stage (providing the report with findings), Audit opinion issuance stage.",
        },
        Point_7: {
          Question: "What documents are provided after an audit?",
          Answer:
            "Report on identified findings and audit report. The audit report can be either modified indicating significant misstatements in the financial statements, or unmodified, indicating the absence of significant misstatements. The audit report is stapled and attached to the financial statements that were audited.",
        },
        Point_8: {
          Question:
            "What documents are required to be provided to the auditor?",
          Answer:
            "Financial statements and tax returns for the period under audit and the previous period, as well as supporting documents such as contracts, invoices, delivery notes, invoices, payroll records, fixed asset records, etc. Additionally, by agreement, you may provide access or preferably an electronic copy of the accounting database.",
        },
      },
    },
  },
  footerSection: {
    Ru: {
      MenuBlock: {
        Title: "Меню",
        AboutUs: "О нас",
        Services: "Услуги",
        Team: "Команда",
        Clients: "Клиенты",
        Feedback: "Обратная связь",
        FAQ: "FAQ",
      },
      ContactsBlock: {
        Title: "Контакты",
        Email: "a-universal@mail.ru",
        Phone: "+7 (499) 130-79-96",
        OfficeAddress: {
          Title: "Офис АУДИТ-УНИВЕРСАЛ",
          Address: "115184, г. Москва, пер. Озерковский, д.12",
        },
      },
      Rights: {
        Label: "© ",
        Text: " Все права защищены",
      },
    },
    Eng: {
      MenuBlock: {
        Title: "Menu",
        AboutUs: "About Us",
        Services: "Services",
        Team: "Team",
        Clients: "Clients",
        Feedback: "Feedback",
        FAQ: "FAQ",
      },
      ContactsBlock: {
        Title: "Contacts",
        Email: "a-universal@mail.ru",
        Phone: "+7 (499) 130-79-96",
        OfficeAddress: {
          Title: "AUDIT-UNIVERSAL Office",
          Address: "115184, Moscow, Ozerkovsky Lane, 12",
        },
      },
      Rights: {
        Label: "© ",
        Text: " All rights reserved",
      },
    },
  },
};

export default sectionsText;
