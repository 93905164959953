import React, { useEffect } from "react";
import "./LandingLoading.css";

import Lottie from "lottie-web";
import LoadingAnimation from "./LoadingAnimation.json";

const LandingLoading = () => {
  
    useEffect(() =>
      Lottie.loadAnimation({
        container: document.querySelector("#app_loading_animation"),
        animationData: LoadingAnimation,
        loop: true,
        autoplay: true,
      })
    );

  return (
    <div className="AppLoadingAnimation">
      <div id="app_loading_animation"></div>
      <h1>Загрузка, пожалуйста подождите...</h1>
    </div>
  );
};

export default LandingLoading;
