import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import "./styles.css";

import LandingLoading from "./LandingLoading";
import sectionsText from "./auditText";

const MobileComponent = React.lazy(() => import("./Mobile"));
const DesktopComponent = React.lazy(() => import("./Desktop"));

function App() {
  const [is_mobile, setIsMobile] = useState(true);
  const [languageState, setLanguageState] = useState(true);

  let LanguageSwitch = languageState ? "Ru" : "Eng";

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const max_size = 900;

    if ((width / height < 1 && width < max_size) || width < max_size)
      setIsMobile(true);
    else setIsMobile(false);
  }, [is_mobile]);

  return (
    <Suspense fallback={<LandingLoading />}>
      {is_mobile ? (
        <div className="App mobile">
          <MobileComponent
            language={LanguageSwitch}
            setLanguage={setLanguageState}
            languageState={languageState}
          />
        </div>
      ) : (
        <div className="App">
          <DesktopComponent
            language={LanguageSwitch}
            setLanguage={setLanguageState}
            languageState={languageState}
          />
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{sectionsText.title[LanguageSwitch]}</title>
      </Helmet>
    </Suspense>
  );
}

export default App;
